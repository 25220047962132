import { useMemo } from "react";
import { UAParser } from "ua-parser-js";

const useCheckIsMobileDevice = () => {
  const isMobileDevice = useMemo(() => {
    const userAgentParser = new UAParser(window.navigator.userAgent);
    const device = userAgentParser.getDevice();
    return device.type === "mobile";
  }, []);

  return isMobileDevice;
};

export default useCheckIsMobileDevice;
