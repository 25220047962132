import Autolinker from "autolinker";
import {
  camelCase,
  capitalize,
  every,
  get,
  isArray,
  isEmpty,
  isEqual,
  isObject,
  isPlainObject,
  isString,
  mapKeys,
  mapValues,
  snakeCase,
  startCase,
  toNumber,
  without,
} from "lodash";
import moment from "moment";
import { validate as uuidValidate, version as uuidVersion } from "uuid";

/* Checks if an object is a subset of another object */
export const isObjectSubset = ({ subset, superset }) => {
  return every(subset, (value, key) => isEqual(value, superset[key]));
};

/* 
  A valid password has at least one number, one special character,
  one uppercase or lowercase alphabetical character.
*/
export const isPasswordValid = (password) => {
  if (!password) return false;

  const validator =
    /^(?=.*[0-9])(?=.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/;

  return validator.test(password);
};

export const snakeCaseToUpperCase = (text) => {
  if (!text) return "";
  return snakeCase(text).toUpperCase();
};

export const titleCase = (text) => {
  if (!text) return "";
  return startCase(text.toLowerCase());
};

export const sentenceCase = (text) => {
  if (!text) return "";
  return capitalize(startCase(text));
};

export const hasText = (value) => value.match(/[a-zA-Z]/);

export const formatNumber = (number, digits = 2) => {
  if (!number) return 0;

  if (!Number.isInteger(number)) {
    return Number(number).toFixed(digits);
  }

  return number;
};

export const getPercentage = (value, total) => {
  const safeValue = value || 0;
  const safeTotal = total || 0;

  if (safeTotal === 0) return 0;

  return Math.round((safeValue / safeTotal) * 100);
};

export const getListNumberingAlphabet = (index) => {
  return String.fromCharCode("A".charCodeAt(0) + index);
};

export const reorderArray = (array, startIndex, endIndex) => {
  const result = [...array];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const replaceInArray = ({ array, index, element }) => [
  ...array.slice(0, index),
  element,
  ...array.slice(index + 1),
];

export const removeInArray = ({ array, index }) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

export const insertInArray = ({ array, index, element }) => [
  ...array.slice(0, index),
  element,
  ...array.slice(index),
];

export const equalPropsWith = (prevProps, nextProps, propNames = []) =>
  every(propNames, (propName) =>
    isEqual(get(prevProps, propName), get(nextProps, propName)),
  );

export const multipleEqualPropsWith = (
  prevProps,
  nextProps,
  equalityFunctions = [],
) =>
  every(equalityFunctions, (equalityFunction) =>
    isEqual(equalityFunction(prevProps, nextProps), true),
  );

export const prepareName = ({ firstName, lastName }) =>
  without([firstName, lastName], "").join(" ");

/* Used to return pervious index in an array in a circular fashion  */
export const getPrevWrapAroundIndex = (currentIndex, arrayLength) => {
  const nextFocus = currentIndex - 1;
  const wrapAround = nextFocus < 0;

  if (wrapAround) {
    return arrayLength - 1;
  }
  return nextFocus % arrayLength;
};

/* Used to return next index in an array in a circular fashion  */
export const getNextWrapAroundIndex = (currentIndex, arrayLength) => {
  return (currentIndex + 1) % arrayLength;
};

/* Converts bytes to an appropriate size scale */
export const bytesToSize = ({ bytes, inBinary = true, decimalPlace = 1 }) => {
  const unit = inBinary ? 1024 : 1000;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return `0 Bytes`;
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(unit)));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / Math.pow(unit, i)).toFixed(decimalPlace)} ${sizes[i]}`;
};

/* Formats time from milliseconds to a more readable format for media */
export const formatTime = (time) => {
  const duration = moment.duration(time);

  if (duration.hours() > 0) {
    return moment.utc(time).format("HH:mm:ss");
  } else {
    return moment.utc(time).format("mm:ss");
  }
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const uuidValidateV4 = (uuid) => {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
};

/* Checks if it is a valid ID (either UUID or integer) */
export const validateId = (id) => {
  if (!id) return false;
  if (uuidValidateV4(id)) return true;
  if (!toNumber(id)) return false;

  return true;
};

export const getUrls = (text) => {
  return Autolinker.parse(text, {
    urls: true,
    email: false,
    phone: false,
    hashtag: false,
    mention: false,
  }).map((match) => match.getUrl());
};

export const removeSpaces = (text) => text.replace(/ +/g, "");

export const getAgentFullName = (AgentObject) =>
  AgentObject
    ? `${AgentObject?.user?.firstName || ""} ${
        AgentObject?.user?.lastName || ""
      }`
    : "";

export const mapKeysDeep = (input, func) => {
  const mappedKeys = mapKeys(input, (_, key) => func(key));
  const mappedValues = mapValues(mappedKeys, (value) => {
    if (isPlainObject(value)) {
      return mapKeysDeep(value, func);
    }

    if (isArray(value)) {
      return value.map((input) => {
        if (!isPlainObject(input) && !isArray(input)) return input;
        return mapKeysDeep(input, func);
      });
    }

    return value;
  });

  return mappedValues;
};

export const convertObjectToCamelCase = (input) =>
  mapKeysDeep(input, camelCase);
export const convertObjectToSnakeCase = (input) =>
  mapKeysDeep(input, snakeCase);

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const filterLanguages = ({ languages, languagesIn }) =>
  languages.filter((language) =>
    languagesIn.some((option) => option === language.ietfLanguageTag),
  );

export const isListObject = ({ data = {}, name = "" }) => {
  if (name) {
    return (
      isObject(data[name]) &&
      Object.keys(data[name]).includes("results") &&
      isArray(data[name].results)
    );
  }

  return (
    isObject(data) &&
    Object.keys(data).includes("results") &&
    isArray(data.results)
  );
};

export const onChangeValidInput = ({ event, onChange }) => {
  const isValidData = event.target.validity.valid || event.target.value === "";
  if (isValidData) onChange(event.target.value);
};

export const getValueStringFromObjectOrList = ({
  data,
  stringKey = "",
  noDataValue = "-",
}) => {
  if (isEmpty(data)) return noDataValue;

  if (isPlainObject(data)) {
    /* TODO: Account for nested objects */
    return stringKey
      ? data?.[stringKey]
        ? data[stringKey]
        : noDataValue
      : Object.values(data).join(", ");
  }

  /* To convert String object/array eg: "[{'xx': 'xxx'}]" to actual object/array */
  const parsedData = (() => {
    if (isString(data)) {
      /* 
        replace all the &quot; with " before doing JSON.parse
        to eliminate (Error) JSON.parse: expected property name or '}' 
      */
      const jsonString = data.replace(/'/g, '"');

      try {
        return JSON.parse(jsonString);
      } catch (e) {
        return data;
      }
    }
    return data;
  })();

  if (isEmpty(parsedData)) return noDataValue;
  if (!isArray(parsedData)) return parsedData;

  return parsedData
    .map((item) => {
      return item[stringKey] || item;
    })
    .join(", ");
};

export const concatenateValuesFromPath = ({
  pathCollection,
  item,
  separator = " ",
}) => {
  const values = pathCollection.reduce(
    (acc, val) => [...acc, get(item, val)],
    [],
  );

  return values.join(separator);
};

export const prepareSelectOption = ({
  optionData = {},
  parsingOptions = {
    isSimpleParsing: false,
    valuePath: "id" /* Supports dot notation and array */,
    labelPath: "name" /* Supports dot notation and array */,
    labelSeparator: " " /* For when concatenateLabel = true*/,
    /* Allow custom label format eg. transform to startCase  */
    labelFormat: (label) => label,
    /* 
      Set true if labelPath is an array of paths to nested properties that has to be joined together
      i.e. ["user.firstName", "user.lastName"]
    */
    concatenateLabel: false,
  },
}) => {
  const {
    isSimpleParsing,
    valuePath,
    labelPath,
    labelSeparator,
    labelFormat,
    concatenateLabel,
  } = parsingOptions;

  const label =
    isString(labelPath) || !concatenateLabel
      ? get(optionData, labelPath)
      : concatenateValuesFromPath({
          item: optionData,
          pathCollection: labelPath,
          separator: labelSeparator,
        });

  const formattedLabel = labelFormat ? labelFormat(label) : label;

  return {
    label: formattedLabel,
    value: get(optionData, valuePath),
    ...(!isSimpleParsing && optionData),
  };
};

export const prepareSelectOptions = ({
  optionsData = [],
  specialOption,
  parsingOptions = null,
}) => {
  const parsedOptions = optionsData.map((optionData) => {
    const selectOption = prepareSelectOption({
      optionData,
      ...(parsingOptions && { parsingOptions }),
    });

    return {
      ...selectOption,
      isDefault: optionData.isDefault,
    };
  });

  if (specialOption) return [specialOption, ...parsedOptions];

  return parsedOptions;
};

/* Generic options parser for when only value and label are needed */
export const prepareAsyncSelectOptions = ({
  optionsData,
  specialOption,
  valuePath = "id",
  labelPath = "name",
  labelSeparator = " ",
  labelFormat,
  concatenateLabel = false,
}) =>
  prepareSelectOptions({
    optionsData,
    specialOption,
    parsingOptions: {
      isSimpleParsing: true,
      valuePath,
      labelPath,
      labelSeparator,
      labelFormat,
      concatenateLabel,
    },
  });

/* Compute the offset when doing pagination in the PaginatedTable component  */
export const getOffsetForPaginatedTable = ({ rowsPerPage, selectedPage }) => {
  const offset = selectedPage === 0 ? 0 : (selectedPage - 1) * rowsPerPage;
  return offset;
};

/*
  Browsers only allow close tab opened by user (won't work on copy-pasted link)
  Ref: https://www.thesitewizard.com/javascripts/close-browser-tab-or-window.shtml
*/
export const handleCloseWindow = () => {
  window.opener = null;
  window.open("", "_self");
  window.close();
};

/*
  We must always verify the message sender when receiving event from window.postMessage
  See https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
*/
export const checkIsTrustedPostMessageEvent = (event) => {
  /* Skip message sent from another window */
  if (event.source !== window) return false;

  /* Skip message if the origin is not the same domain as current window to prevent cross-origin attack */
  if (event.origin !== window.location.origin) return false;

  /* Skip message if data type is not provided */
  if (!event.data?.type) return false;

  return true;
};
